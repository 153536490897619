<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
// import sound from "./common/sound";
export default {
  name: "App",

  data: () => ({
    //
  }),
  async created() {
    // 오디오 체크음
    // const preSnd = new Audio("data:audio/mp3;base64," + sound.short);
    // preSnd.play();

    if (/iPhone/i.test(navigator.userAgent)) {
      // iPhone에서 실행 중
      this.$store.commit("setIphone");
    }

  },
};
</script>

<style scoped>
.v-application {
  background-color: black !important;
}
</style>