/* eslint-disable no-undef */
import '../plugins/axios';
import { setAuthHeader } from '../plugins/axios';

var service = {
    setAuth(pw) {
      setAuthHeader(pw);
    },
    ping(address) {
      let config = {
        headers: {
          'origin-server': address
        }
      }
      if (process.env.NODE_ENV === 'production') {
        return axios.post('/api/ping',null, config);
      } else {
        return axios.post(`http://${address}/api/ping`, null, config);
      }
    },
    websocketConnect(address) {
      if (process.env.NODE_ENV === 'production') {
        return new WebSocket(`wss://${location.host}/ws-event/?origin_server=${address}`);
      } else {
        return new WebSocket(`ws://${address}/ws-event`);
      }
      
    },
    wwebsocketConnect(address) {
      return new WebSocket(address);
    },
    getStatus(address, param) {
      let config = {
        headers: {
          'origin-server': address
        }
      }
      if (process.env.NODE_ENV === 'production') {
        return axios.post('/api/get-state', param, config);
      } else {
        return axios.post(`http://${address}/api/get-state`, param, config);
      }
    },
    getBalance(address, param) {
      let config = {
        headers: {
          'origin-server': address
        }
      }
      if (process.env.NODE_ENV === 'production') {
        return axios.post('/api/get-balance', param, config);
      } else {
        return axios.post(`http://${address}/api/get-balance`, param, config);
      }    
    },
    getTick(address, param) {
      let config = {
        headers: {
          'origin-server': address
        }
      }
      if (process.env.NODE_ENV === 'production') {
        return axios.post('/api/get-tick', param, config);
      } else {
        return axios.post(`http://${address}/api/get-tick`, param, config);
      }
    },
    tradeSell(address, param) {
      let config = {
        headers: {
          'origin-server': address
        }
      }
      if (process.env.NODE_ENV === 'production') {
        return axios.post('/api/trade-sell', param, config);
      } else {
        return axios.post(`http://${address}/api/trade-sell`, param, config);
      }
    },
    tradeCancel(address, param) {
      let config = {
        headers: {
          'origin-server': address
        }
      }
      if (process.env.NODE_ENV === 'production') {
        return axios.post('/api/trade-cancel', param, config);
      } else {
        return axios.post(`http://${address}/api/trade-cancel`, param, config);
      }
    },
    reset(address, param) {
      let config = {
        headers: {
          'origin-server': address
        }
      }
      if (process.env.NODE_ENV === 'production') {
        return axios.post('/api/reset-state', param, config);
      } else {
        return axios.post(`http://${address}/api/reset-state`, param, config);
      }
    },
    getAudio(address, param) {
      let config = {
        headers: {
          'origin-server': address
        }
      }
      if (process.env.NODE_ENV === 'production') {
        return axios.post('/api/get-tts', param, config);
      } else {
        return axios.post(`http://${address}/api/get-tts`, param, config);
      }
    }
  }

export default service;



