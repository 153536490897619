<template>
  <v-container class="container--fluid fill-height">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="8" md="4" lg="4">
        <v-card>
          <v-card-title class="subtitle-2 pl-1 pr-1">
            <v-col cols="8">
              <v-chip color="orange" label onclick="window.location.reload();">
                <span class="white--text">{{ exchange.pair }}</span>
              </v-chip>
              <span
                class="yellow--text text--darken-1 caption font-weight-bold text-h6"
                >&nbsp;Binance 2&nbsp;</span
              >
              <label class="orange--text"
                >[{{ exchange.symbol }}-{{ exchange.rank }}]
                <label class="red--text font-weight-bold">{{
                  important
                }}</label></label
              >
            </v-col>
            <v-col cols="4" class="d-flex justify-end">
              <v-btn small block color="error" @click="reset()">매매종료</v-btn>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-row class="orange--text subtitle-2"
              >제목: {{ exchange.title }}</v-row
            >
            <!-- <v-row class="indigo darken-4 white--text subtitle-2"
              >공지: {{ exchange.notice }}</v-row
            > -->
            <v-row
              class="subtitle-2 font-weight-bold"
              :style="{ color: titleColor, backgroundColor: titleBGColor }"
              >공지: {{ exchange.notice }}</v-row
            >
            <v-row>
              <v-col cols="8">
                <v-row
                  >구매금액:&nbsp;
                  <label>{{ startTotal }} {{ exchange.pair }}</label></v-row
                >
                <v-row class="headline white--text"
                  >평가금액:&nbsp;
                  <label
                    class="font-weight-bold"
                    :class="[
                      startTotal < currentTotal ? 'red--text' : 'blue--text',
                    ]"
                    >{{ currentTotal }} {{ exchange.pair }}</label
                  ></v-row
                >
                <v-row
                  >시작가격:&nbsp; {{ startPrice }} {{ exchange.pair }}</v-row
                >
                <v-row
                  >평균가격:&nbsp; {{ avgPrice }} {{ exchange.pair
                  }}<label
                    v-if="avgPrice == 0"
                    class="red--text font-weight-bold"
                    >&nbsp;[평균가없음]</label
                  ></v-row
                >
                <v-row
                  >현재가격:&nbsp; {{ exchange.balance.currentPrice }}
                  {{ exchange.pair }}</v-row
                >
                <v-row
                  class="headline white--text"
                  @click="tradeCurrent()"
                  align="center"
                  >손익률:&nbsp;
                  <label
                    class="font-weight-bold"
                    :class="[profit > 0 ? 'red--text' : 'blue--text']"
                    >{{ profit.toFixed(2) }} %</label
                  ><label class="font-weight-bold green--text subtitle-2"
                    >&nbsp;({{ openProfit.toFixed(2) }} %)</label
                  ></v-row
                >
              </v-col>
              <v-col cols="4">
                <v-row class="mb-6">
                  <v-btn small block class="green" @click="tradeCancel()"
                    >전체 주문취소</v-btn
                  >
                </v-row>
                <v-row>
                  <v-slider
                    v-model="balance"
                    max="100"
                    min="0"
                    label="수량"
                    thumb-label="always"
                    class="pb-0 mb-0"
                  ></v-slider>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="grey darken-3">
            <v-row
              class="pt-1 pb-1"
              :class="{ 'border-class': profit > 90 && profit <= 300 }"
            >
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                class="light-blue darken-1"
                @click="tradeSell(300)"
                >300</v-btn
              >
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(275)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                class="light-blue darken-1"
                @click="tradeSell(250)"
                >250</v-btn
              >
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(225)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                class="light-blue darken-1"
                @click="tradeSell(200)"
                >200</v-btn
              >
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(175)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                class="light-blue darken-1"
                @click="tradeSell(150)"
                >150</v-btn
              >
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(125)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                class="light-blue darken-1"
                @click="tradeSell(100)"
                >100</v-btn
              >
              <v-btn
                x-small
                :height="profit > 90 && profit <= 300 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(95)"
              ></v-btn>
            </v-row>

            <v-row
              class="pt-1 pb-1"
              :class="{ 'border-class': profit > 45 && profit <= 90 }"
            >
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                class="brown darken-4"
                @click="tradeSell(90)"
                >90%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(85)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                class="brown darken-4"
                @click="tradeSell(80)"
                >80%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(75)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                class="brown darken-4"
                @click="tradeSell(70)"
                >70%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(65)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                class="brown darken-4"
                @click="tradeSell(60)"
                >60%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(55)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                class="brown darken-4"
                @click="tradeSell(50)"
                >50%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 45 && profit <= 90 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(47.5)"
              ></v-btn>
            </v-row>

            <v-row
              class="pt-1 pb-1"
              :class="{ 'border-class': profit > 20 && profit <= 45 }"
            >
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(45)"
                >45%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(42.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(40)"
                >40%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(37.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(35)"
                >35%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(32.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(30)"
                >30%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(27.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(25)"
                >25%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 20 && profit <= 45 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(22.5)"
              ></v-btn>
            </v-row>

            <v-row
              class="pt-1 pb-1"
              :class="{ 'border-class': profit > 10 && profit <= 20 }"
            >
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(20)"
                >20%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(19)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(18)"
                >18%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(17)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(16)"
                >16%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(15)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(14)"
                >14%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(13)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                class="red darken-4"
                @click="tradeSell(12)"
                >12%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 10 && profit <= 20 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(11)"
              ></v-btn>
            </v-row>

            <v-row
              class="pt-1 pb-1"
              :class="{ 'border-class': profit > 5 && profit <= 10 }"
            >
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(10)"
                >10%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(9.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(9)"
                >9%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(8.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(8)"
                >8%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(7.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(7)"
                >7%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(6.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(6)"
                >6%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 5 && profit <= 10 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(5.5)"
              ></v-btn>
            </v-row>

            <v-row
              class="pt-1 pb-1"
              :class="{ 'border-class': profit > 0 && profit <= 5 }"
            >
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(5)"
                >5%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(4.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(4)"
                >4%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(3.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(3)"
                >3%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(2.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(2)"
                >2%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(1.5)"
              ></v-btn>
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                class="orange darken-4"
                @click="tradeSell(1)"
                >1%</v-btn
              >
              <v-btn
                x-small
                :height="profit > 0 && profit <= 5 ? '35' : ''"
                icon
                class="ma-auto blue-grey"
                @click="tradeSell(0.6)"
              ></v-btn>
            </v-row>

            <v-row class="pt-1 pb-1" :class="{ 'border-class': profit <= 0 }">
              <v-btn
                small
                :height="profit <= 0 ? '35' : ''"
                class="mr-1 gray"
                @click="tradeSell(0.2)"
                >0%</v-btn
              >
              <v-btn
                small
                :height="profit <= 0 ? '35' : ''"
                class="mr-1 gray"
                @click="tradeSell(-0.5)"
                >-0.5%</v-btn
              >
              <v-btn
                small
                :height="profit <= 0 ? '35' : ''"
                class="mr-1 gray"
                @click="tradeSell(-1)"
                >-1%</v-btn
              >
              <v-btn
                small
                :height="profit <= 0 ? '35' : ''"
                class="mr-1 gray"
                @click="tradeSell(-2)"
                >-2%</v-btn
              >
              <v-btn
                small
                :height="profit <= 0 ? '35' : ''"
                class="gray"
                @click="tradeSell(-3.5)"
                >-3.5%</v-btn
              >
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-textarea
              v-model="log"
              outlined
              readonly
              label="매매 로그"
              class="blue-grey darken-4"
            ></v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BinanceView",
  components: {},
  data() {
    return {
      balance: 100,
      log: "",
      logCount: 0,
      btcPrice: 0,
    };
  },
  computed: {
    important() {
      return this.exchange.title.includes("상장") ? "중요!!" : "";
    },
    key() {
      return this.$route.path;
    },
    exchange() {
      return this.$store.state.exchange.binanceSub;
    },
    active() {
      return this.$store.state.exchange.binanceSub.active;
    },
    startPrice() {
      var s = parseFloat(
        this.exchange.startPrice ? this.exchange.startPrice : 0
      );
      if (this.exchange.pair != "BTC") {
        return s.toLocaleString();
      } else {
        return s.toFixed(8);
      }
    },
    avgPrice() {
      var s = parseFloat(
        this.exchange.balance.avgPrice ? this.exchange.balance.avgPrice : 0
      );
      if (this.exchange.pair != "BTC") {
        return s.toLocaleString();
      } else {
        return s.toFixed(8);
      }
    },
    profit() {
      return this.exchange.profit;
    },
    openProfit() {
      return this.exchange.openProfit;
    },
    startTotal() {
      var total = this.exchange.balance.total ? this.exchange.balance.total : 0;
      var c = this.exchange.startPrice
        ? parseFloat(this.exchange.startPrice)
        : 0;

      if (this.exchange.pair === "BTC") {
        return (total * c).toFixed(8);
      } else {
        return parseInt(total * c).toLocaleString();
      }
    },
    currentTotal() {
      var total = this.exchange.balance.total ? this.exchange.balance.total : 0;
      var c = this.exchange.balance.currentPrice
        ? this.exchange.balance.currentPrice
        : 0;

      if (this.exchange.pair === "BTC") {
        return (total * c).toFixed(8);
      } else {
        return parseInt(total * c).toLocaleString();
      }
    },
    titleColor() {
      if (this.exchange.title.includes("업비트")) {
        return "#ffffff";
      } else if (this.exchange.title.includes("바이낸스")) {
        return "#f0b90b";
      } else if (this.exchange.title.includes("빗썸")) {
        return "#ffffff";
      }
      return "#ffffff";
    },
    titleBGColor() {
      if (this.exchange.title.includes("업비트")) {
        return "#1261c4";
      } else if (this.exchange.title.includes("바이낸스")) {
        return "#000000";
      } else if (this.exchange.title.includes("빗썸")) {
        return "#fe9601";
      }
      return "#000000";
    },
  },
  methods: {
    tradeSell(pricePer) {
      if (pricePer < 0) {
        if (!confirm("손절거래 입니다. 진행하시겠습니까?")) {
          return;
        }
      }
      this.$store
        .dispatch("tradeSell", {
          exchange: "BS",
          pricePercent: pricePer,
          balancePercent: this.balance,
        })
        .then(() => {
          this.addLog("매도 주문 요청 [성공]");
        })
        .catch(() => {
          this.addLog("매도 주문 요청 [실패]");
        });
    },
    tradeCurrent() {
      var per = parseFloat(this.profit.toFixed(2)) - 0.5;
      if (!confirm(`매도를 [이익:${per}%] 진행하시겠습니까?`)) {
        return;
      }
      this.$store
        .dispatch("tradeSell", {
          exchange: "BS",
          pricePercent: per,
          balancePercent: this.balance,
        })
        .then(() => {
          this.addLog("매도 주문 요청 [성공]");
        })
        .catch(() => {
          this.addLog("매도 주문 요청 [실패]");
        });
    },
    tradeCancel() {
      this.$store
        .dispatch("tradeCancel", { exchange: "BS" })
        .then(() => {
          this.addLog("주문 취소 요청 [성공]");
        })
        .catch(() => {
          this.addLog("주문 취소 요청 [실패]");
        });
    },
    reset() {
      if (!confirm("거래를 종료하시겠습니까?")) {
        return;
      }
      this.$store.dispatch("reset", { exchange: "BS" });
    },
    addLog(msg) {
      this.log = `[${this.logCount}]` + msg + "\n" + this.log;
      this.logCount++;
    },
  },
};
</script>
<style scoped>
.border-class {
  border: 1px solid #fbff00; /* 원하는 테두리 스타일 */
}
</style>
