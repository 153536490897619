<template>
  <v-container class="container--fluid fill-height">
    <v-row v-if="!fullscreen">
      <v-col cols="9">
        <v-btn
          small
          class="grey--text text--darken-2 grey darken-4"
          onclick="window.location.reload();"
          v-if="!hide"
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
          small
          @click="enableVibrate()"
          class="grey--text text--darken-2 grey darken-4"
          v-if="!hide"
        >
          <v-icon small v-if="vibrate">fa-check</v-icon
          ><v-icon small>mdi-vibrate</v-icon>
        </v-btn>
        <v-btn
          small
          @click="enableBeep()"
          class="grey--text text--darken-2 grey darken-4"
          v-if="!hide"
        >
          <v-icon small v-if="beep">fa-check</v-icon
          ><v-icon small>mdi-bell</v-icon>
        </v-btn>
        <v-btn
          small
          @click="enableVoice()"
          class="grey--text text--darken-2 grey darken-4"
          v-if="!hide"
        >
          <v-icon small v-if="voice">fa-check</v-icon>
          <v-icon small>mdi-account-voice</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="3" class="text-right" v-if="$store.state.iphone">
        <v-btn icon @click="hide = !hide">
          <v-icon color="black">fa-ellipsis-h</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="3" class="text-right" v-else>
        <v-btn icon @click="changeFullscreen()">
          <v-icon color="grey">fa-expand</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <v-col col="12">
        <v-card class="black">
          <v-card-text
            class="display-1 text-center font-weight-light"
            :class="[peekTime ? 'blue-grey--text' : 'grey--text']"
          >
            <br />
            <br />
            <br />
            <br />
            {{ time }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "WaitView",
  data() {
    return {
      interval: null,
      time: "",
      fullscreen: false,
      hide: false,
    };
  },
  computed: {
    beep() {
      return this.$store.state.beep;
    },
    voice() {
      return this.$store.state.voice;
    },
    vibrate() {
      return this.$store.state.vibrate;
    },
    peekTime() {
      return this.time.includes(":00:") || this.time.includes(":30:");
    },
  },
  methods: {
    async changeFullscreen() {
      document.body.requestFullscreen();

      let wakeLock = null;
      try {
        wakeLock = await navigator.wakeLock.request("screen");
        wakeLock.addEventListener("release", () => {
          console.log("Screen Wake Lock was released");
        });
        console.log("Screen Wake Lock acquired");
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    },
    enableVibrate() {
      if (this.vibrate) {
        localStorage.removeItem("vibrate");
        this.$store.commit("updateVibrate", { enabled: false });
      } else {
        localStorage.setItem("vibrate", "1");
        this.$store.commit("updateVibrate", { enabled: true });
      }
    },
    enableVoice() {
      if (this.voice) {
        localStorage.removeItem("voice");
        this.$store.commit("updateVoice", { enabled: false });
      } else {
        localStorage.setItem("voice", "1");
        localStorage.removeItem("beep");
        this.$store.commit("updateVoice", { enabled: true });
        this.$store.commit("updateBeep", { enabled: false });
      }
    },
    enableBeep() {
      if (this.beep) {
        localStorage.removeItem("beep");
        this.$store.commit("updateBeep", { enabled: false });
      } else {
        localStorage.setItem("beep", "1");
        localStorage.removeItem("voice");
        this.$store.commit("updateVoice", { enabled: false });
        this.$store.commit("updateBeep", { enabled: true });
      }
    },
    updateTime() {
      this.time = Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      }).format();
    },
  },
  created() {
    this.updateTime();
    this.interval = setInterval(this.updateTime, 1000);

    // sound save
    const voice =
      localStorage.getItem("voice") && localStorage.getItem("voice") === "1";
    const beep =
      localStorage.getItem("beep") && localStorage.getItem("beep") === "1";
    const vibrate =
      localStorage.getItem("vibrate") &&
      localStorage.getItem("vibrate") === "1";

    if (beep) {
      this.$store.commit("updateBeep", { enabled: true });
    } else if (voice) {
      this.$store.commit("updateVoice", { enabled: true });
    }
    if (vibrate) {
      this.$store.commit("updateVibrate", { enabled: true });
    }

    const t = this;
    document.addEventListener("fullscreenchange", () => {
      if (document.fullscreenElement) {
        t.fullscreen = true;
      } else {
        t.fullscreen = false;
      }
    });
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>
