<template>
  <v-container class="container--fluid">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="8" md="4" lg="4" class="pl-2">
        <!-- 업비트 버튼 -->
        <v-badge
          v-if="this.$store.state.status.upbit"
          color="green--text"
          :content="
            (upbitProfit > 10
              ? '(' + upbitOpenProfit.toFixed(0) + '%)'
              : '(' + upbitOpenProfit.toFixed(1)) + '%)'
          "
          overlap
          top
          left
          offset-x="85"
        >
          <v-badge
            :color="
              upbitProfit > 0
                ? 'transparent red--text'
                : 'transparent blue--text'
            "
            :content="
              upbitBalance
                ? (upbitProfit > 10
                    ? upbitProfit.toFixed(0)
                    : upbitProfit.toFixed(1)) + '%'
                : '-%'
            "
            overlap
            left
            offset-x="40"
          >
            <v-btn
              class="black font-weight-bold pa-0 ml-1"
              @click="id = 'U'"
              :class="[id == 'U' ? 'teal darken-3' : '']"
            >
              <v-chip
                x-small
                label
                class="non-chip font-weight-bold black--text"
                color="orange darken-2"
              >
                {{ this.$store.state.exchange.upbit.symbol }}
                <label class="white--text text-body-2 font-weight-bold">
                  {{ this.$store.state.exchange.upbit.rank }}
                </label>
              </v-chip>
              <span class="blue--text text--darken-3 caption font-weight-bold" style="background-color: white;"
                >Upbit</span
              >
            </v-btn>
          </v-badge>
        </v-badge>

        <!-- 업비트 Sub 버튼 -->
        <v-badge
          v-if="this.$store.state.status.upbitSub"
          color="green--text"
          :content="
            (upbitProfit > 10
              ? '(' + upbitSubOpenProfit.toFixed(0) + '%)'
              : '(' + upbitSubOpenProfit.toFixed(1)) + '%)'
          "
          overlap
          top
          right
          offset-x="60"
        >
          <v-badge
            :color="
              upbitSubProfit > 0
                ? 'transparent red--text'
                : 'transparent blue--text'
            "
            :content="
              upbitSubBalance
                ? (upbitSubProfit > 10
                    ? upbitSubProfit.toFixed(0)
                    : upbitSubProfit.toFixed(1)) + '%'
                : '-%'
            "
            overlap
            left
            offset-x="40"
          >
            <v-btn
              class="black font-weight-bold pa-0 ml-1"
              @click="id = 'US'"
              :class="[id == 'US' ? 'teal darken-3' : '']"
            >
              <v-chip
                x-small
                label
                class="non-chip font-weight-bold black--text"
                color="orange darken-2"
              >
                {{ this.$store.state.exchange.upbitSub.symbol }}
                <label class="white--text text-body-2 font-weight-bold">
                  {{ this.$store.state.exchange.upbitSub.rank }}
                </label>
              </v-chip>
              <span class="blue--text text--darken-3 caption font-weight-bold" style="background-color: white;"
                >Upbit2</span
              >
            </v-btn>
          </v-badge>
        </v-badge>

        <!-- 바이낸스 버튼 -->
        <v-badge
          v-if="this.$store.state.status.binance"
          color="green--text"
          :content="
            (binanceProfit > 10
              ? '(' + binanceOpenProfit.toFixed(0) + '%)'
              : '(' + binanceOpenProfit.toFixed(1)) + '%)'
          "
          overlap
          top
          left
          offset-x="85"
        >
          <v-badge
            :color="
              binanceProfit > 0
                ? 'transparent red--text'
                : 'transparent blue--text'
            "
            :content="
              binanceBalance
                ? (binanceProfit > 10
                    ? binanceProfit.toFixed(0)
                    : binanceProfit.toFixed(1)) + '%'
                : '-%'
            "
            overlap
            left
            offset-x="40"
          >
            <v-btn
              class="black font-weight-bold pa-0 ml-1"
              @click="id = 'B'"
              :class="[id == 'B' ? 'teal darken-3' : '']"
            >
              <v-chip
                x-small
                label
                class="non-chip font-weight-bold black--text"
                color="orange darken-2"
              >
                {{ this.$store.state.exchange.binance.symbol }}
                <label class="white--text text-body-2 font-weight-bold">
                  {{ this.$store.state.exchange.binance.rank }}
                </label>
              </v-chip>
              <span class="yellow--text text--darken-1 caption font-weight-bold"
                >Binance</span
              >
            </v-btn>
          </v-badge>
        </v-badge>

        <!-- 바이낸스 SUB 버튼 -->
        <v-badge
          v-if="this.$store.state.status.binanceSub"
          color="green--text"
          :content="
            (binanceSubOpenProfit > 10
              ? '(' + binanceSubOpenProfit.toFixed(0) + '%)'
              : '(' + binanceSubOpenProfit.toFixed(1)) + '%)'
          "
          overlap
          top
          left
          offset-x="85"
        >
          <v-badge
            v-if="this.$store.state.status.binanceSub"
            :color="
              binanceSubProfit > 0
                ? 'transparent red--text'
                : 'transparent blue--text'
            "
            :content="
              binanceSubBalance
                ? (binanceSubProfit > 10
                    ? binanceSubProfit.toFixed(0)
                    : binanceSubProfit.toFixed(1)) + '%'
                : '-%'
            "
            overlap
            left
            offset-x="40"
          >
            <v-btn
              class="black font-weight-bold pa-0 ml-1"
              @click="id = 'BS'"
              :class="[id == 'BS' ? 'teal darken-3' : '']"
            >
              <v-chip
                x-small
                label
                class="non-chip font-weight-bold black--text"
                color="orange darken-2"
              >
                {{ this.$store.state.exchange.binanceSub.symbol }}
                <label class="white--text text-body-2 font-weight-bold">
                  {{ this.$store.state.exchange.binanceSub.rank }}
                </label>
              </v-chip>
              <span class="yellow--text text--darken-1 caption font-weight-bold"
                >Binance2</span
              >
            </v-btn>
          </v-badge>
        </v-badge>

        <!-- 바이낸스 선물 버튼 -->
        <v-badge
          v-if="this.$store.state.status.binanceFutures"
          color="green--text"
          :content="
            (binanceFuturesOpenProfit > 10
              ? '(' + binanceFuturesOpenProfit.toFixed(0) + '%)'
              : '(' + binanceFuturesOpenProfit.toFixed(1)) + '%)'
          "
          overlap
          top
          left
          offset-x="85"
        >
          <v-badge
            :color="
              binanceFuturesProfit > 0
                ? 'transparent red--text'
                : 'transparent blue--text'
            "
            :content="
              binanceFuturesBalance
                ? (binanceFuturesProfit > 10
                    ? binanceFuturesProfit.toFixed(0)
                    : binanceFuturesProfit.toFixed(1)) + '%'
                : '-%'
            "
            overlap
            left
            offset-x="40"
          >
            <v-btn
              class="black font-weight-bold pa-0 ml-1"
              @click="id = 'BF'"
              :class="[id == 'BF' ? 'teal darken-3' : '']"
            >
              <v-chip
                x-small
                label
                class="non-chip font-weight-bold black--text"
                color="orange darken-2"
              >
                {{ this.$store.state.exchange.binanceFutures.symbol }}
                <label class="white--text text-body-2 font-weight-bold">
                  {{ this.$store.state.exchange.binanceFutures.rank }}
                </label>
              </v-chip>
              <span class="yellow--text text--darken-1 caption font-weight-bold"
                >Binance<label class="red--text">M</label></span
              >
            </v-btn>
          </v-badge>
        </v-badge>

        <!-- 바이낸스 선물 SUB 버튼 -->
        <v-badge
          v-if="this.$store.state.status.binanceFuturesSub"
          color="green--text"
          :content="
            (binanceFuturesSubOpenProfit > 10
              ? '(' + binanceFuturesSubOpenProfit.toFixed(0) + '%)'
              : '(' + binanceFuturesSubOpenProfit.toFixed(1)) + '%)'
          "
          overlap
          top
          left
          offset-x="85"
        >
          <v-badge
            :color="
              binanceFuturesSubProfit > 0
                ? 'transparent red--text'
                : 'transparent blue--text'
            "
            :content="
              binanceFuturesSubBalance
                ? (binanceFuturesSubProfit > 10
                    ? binanceFuturesSubProfit.toFixed(0)
                    : binanceFuturesSubProfit.toFixed(1)) + '%'
                : '-%'
            "
            overlap
            left
            offset-x="40"
          >
            <v-btn
              class="black font-weight-bold pa-0 ml-1"
              @click="id = 'BFS'"
              :class="[id == 'BFS' ? 'teal darken-3' : '']"
            >
              <v-chip
                x-small
                label
                class="non-chip font-weight-bold black--text"
                color="orange darken-2"
              >
                {{ this.$store.state.exchange.binanceFuturesSub.symbol }}
                <label class="white--text text-body-2 font-weight-bold">
                  {{ this.$store.state.exchange.binanceFuturesSub.rank }}
                </label>
              </v-chip>
              <span class="yellow--text text--darken-1 caption font-weight-bold"
                >Binance<label class="red--text">M</label>2</span
              >
            </v-btn>
          </v-badge>
        </v-badge>

        <!-- 게이트아이오 버튼 -->
        <!-- <v-badge
          v-if="this.$store.state.status.gateio"
          :color="
            gateioProfit > 0
              ? 'transparent red--text'
              : 'transparent blue--text'
          "
          :content="
            (gateioProfit > 10
              ? gateioProfit.toFixed(0)
              : gateioProfit.toFixed(1)) + '%'
          "
          overlap
          left
        >
          <v-btn
            class="black font-weight-bold pa-0 ml-2 mr-2"
            @click="id = 'G'"
            :class="[id == 'G' ? 'teal darken-3' : '']"
          >
            <v-chip
              x-small
              label
              class="non-chip font-weight-bold black--text"
              color="orange darken-2"
            >
              {{ this.$store.state.exchange.gateio.symbol }}
              <label class="white--text text-body-2 font-weight-bold">
                {{ this.$store.state.exchange.gateio.rank }}
              </label>
            </v-chip>
            <span>G-IO</span>
          </v-btn>
        </v-badge> -->

        <!-- FTX 선물 버튼 -->
        <!-- <v-badge
          v-if="this.$store.state.status.ftx"
          :color="
            ftxProfit > 0 ? 'transparent red--text' : 'transparent blue--text'
          "
          :content="
            (ftxProfit > 10 ? ftxProfit.toFixed(0) : ftxProfit.toFixed(1)) + '%'
          "
          overlap
          left
        >
          <v-btn
            class="black font-weight-bold pa-0 ml-2 mr-2"
            @click="id = 'F'"
            :class="[id == 'F' ? 'teal darken-3' : '']"
          >
            <v-chip
              x-small
              label
              class="non-chip font-weight-bold black--text"
              color="orange darken-2"
            >
              {{ this.$store.state.exchange.ftx.symbol }}
              <label class="white--text text-body-2 font-weight-bold">
                {{ this.$store.state.exchange.ftx.rank }}
              </label>
            </v-chip>
            <span>FTX</span>
          </v-btn>
        </v-badge> -->

        <!-- Bithumb 선물 버튼 -->
        <v-badge
          v-if="this.$store.state.status.bithumb"
          color="green--text"
          :content="
            (upbitProfit > 10
              ? '(' + bithumbOpenProfit.toFixed(0) + '%)'
              : '(' + bithumbOpenProfit.toFixed(1)) + '%)'
          "
          overlap
          top
          left
          offset-x="85"
        >
          <v-badge
            :color="
              bithumbProfit > 0
                ? 'transparent red--text'
                : 'transparent blue--text'
            "
            :content="
              bithumbBalance
                ? (bithumbProfit > 10
                    ? bithumbProfit.toFixed(0)
                    : bithumbProfit.toFixed(1)) + '%'
                : '-%'
            "
            overlap
            left
            offset-x="40"
          >
            <v-btn
              class="black font-weight-bold pa-0 ml-1"
              @click="id = 'BT'"
              :class="[id == 'BT' ? 'teal darken-3' : '']"
            >
              <v-chip
                x-small
                label
                class="non-chip font-weight-bold black--text"
                color="orange darken-2"
              >
                {{ this.$store.state.exchange.bithumb.symbol }}
                <label class="white--text text-body-2 font-weight-bold">
                  {{ this.$store.state.exchange.bithumb.rank }}
                </label>
              </v-chip>
              <span class="orange--text text--darken-2 caption font-weight-bold" style="background-color: white;"
                >Bithumb</span
              >
            </v-btn>
          </v-badge>
        </v-badge>

        <!-- <v-badge
          color="transparent"
          :content="this.$store.state.exchange.huobi.rank"
          overlap
          left
        >
          <v-btn class="black font-weight-bold" v-if="this.$store.state.status.huobi" value='H'>
          <v-chip x-small label class="non-chip font-weight-bold black--text" color="orange darken-2">
            {{this.$store.state.exchange.huobi.symbol}}
            <label class="white--text text-body-2 font-weight-bold">
              {{ this.$store.state.exchange.huobi.rank }}
            </label>
          </v-chip>
          <span>후오비</span>
        </v-btn>
        </v-badge> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "../../common/service";

export default {
  name: "CustomNavigation",
  created() {
    // 머니서버 웹소켓 연결.
    // 데이터 초기화
    this.$store.dispatch("connectWebsockt");
  },
  destroyed() {
    // 머니서버 웹소켓 해제
    // 기타 서비스 종료
    this.$store.dispatch("disconnectWebsockt");
  },
  data() {
    return {
      id: null,
      wsTimer: null,
      // upbit variable
      upbitTimer: null,
      upbitWebsocket: null,
      upbitBool: false,
      upbitPrice: 0,
      // upbit Sub variable
      upbitSubTimer: null,
      upbitSubWebsocket: null,
      upbitSubBool: false,
      upbitSubPrice: 0,
      // binance variable
      binanceTimer: null,
      binanceWebsocket: null,
      binanceBool: false,
      binancePrice: 0,
      // binance sub variable
      binanceSubTimer: null,
      binanceSubWebsocket: null,
      binanceSubBool: false,
      binanceSubPrice: 0,
      // binance futures variable
      binanceFuturesTimer: null,
      binanceFuturesWebsocket: null,
      binanceFuturesBool: false,
      binanceFuturesPrice: 0,
      // binance futures sub variable
      binanceFuturesSubTimer: null,
      binanceFuturesSubWebsocket: null,
      binanceFuturesSubBool: false,
      binanceFuturesSubPrice: 0,
      // gateio variable
      gateioTimer: null,
      gateioWebsocket: null,
      gateioBool: false,
      gateioPrice: 0,
      // ftx variable
      ftxTimer: null,
      ftxWebsocket: null,
      ftxBool: false,
      ftxPrice: 0,
      // bithumb variable
      bithumbTimer: null,
      bithumbWebsocket: null,
      bithumbBool: false,
      bithumbPrice: 0,
      // huobi variable
      huobiTimer: null,
      huobiWebsocket: null,
      huobiBool: false,
      huobiPrice: 0,
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
    wsConn() {
      return this.$store.state.websocket.conn;
    },
    exUpbit() {
      return this.$store.state.status.upbit;
    },
    exUpbitSub() {
      return this.$store.state.status.upbitSub;
    },
    exBinance() {
      return this.$store.state.status.binance;
    },
    exBinanceSub() {
      return this.$store.state.status.binanceSub;
    },
    exBinanceFutures() {
      return this.$store.state.status.binanceFutures;
    },
    exBinanceFuturesSub() {
      return this.$store.state.status.binanceFuturesSub;
    },
    exGateio() {
      return this.$store.state.status.gateio;
    },
    exFtx() {
      return this.$store.state.status.ftx;
    },
    exBithumb() {
      return this.$store.state.status.bithumb;
    },
    exHuobi() {
      return this.$store.state.status.huobi;
    },
    select() {
      return this.$store.state.select;
    },
    upbitBalance() {
      return this.$store.state.exchange.upbit.balance.total;
    },
    upbitProfit() {
      var ex = this.$store.state.exchange.upbit;

      var avgPrice;
      var currentPrice;
      if (ex.pair == "KRW") {
        avgPrice = ex.balance.avgPrice ? ex.balance.avgPrice : 0;
        currentPrice = ex.balance.currentPrice ? ex.balance.currentPrice : 0;
      } else if (ex.pair == "BTC") {
        avgPrice =
          (ex.balance.avgPrice ? ex.balance.avgPrice : 0) /
          this.$store.state.btcPrice;
        currentPrice = ex.balance.currentPrice ? ex.balance.currentPrice : 0;
      } else {
        avgPrice = 0;
        currentPrice = 0;
      }

      var profit;
      if (avgPrice == 0 || currentPrice == 0) {
        profit = 0;
      } else {
        profit = (currentPrice / avgPrice) * 100 - 100;
      }
      this.$store.commit("updateProfit", { t: "U", profit });
      return profit;
    },
    upbitOpenProfit() {
      var ex = this.$store.state.exchange.upbit;

      var startPrice;
      var currentPrice;
      if (ex.pair == "KRW") {
        startPrice = ex.startPrice ? ex.startPrice : 0;
        currentPrice = ex.balance.currentPrice ? ex.balance.currentPrice : 0;
      } else if (ex.pair == "BTC") {
        startPrice = ex.startPrice ? ex.startPrice : 0;
        currentPrice = ex.balance.currentPrice ? ex.balance.currentPrice : 0;
      } else {
        startPrice = 0;
        currentPrice = 0;
      }

      var profit;
      if (startPrice == 0 || currentPrice == 0) {
        profit = 0;
      } else {
        profit = (currentPrice / startPrice) * 100 - 100;
      }
      this.$store.commit("updateOpenProfit", { t: "U", profit });
      return profit;
    },
    upbitSubBalance() {
      return this.$store.state.exchange.upbitSub.balance.total;
    },
    upbitSubProfit() {
      var ex = this.$store.state.exchange.upbitSub;
      var s;
      var c;
      if (ex.pair == "KRW") {
        s = ex.balance.avgPrice ? ex.balance.avgPrice : 0;
        c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;
      } else if (ex.pair == "BTC") {
        s =
          (ex.balance.avgPrice ? ex.balance.avgPrice : 0) /
          this.$store.state.btcPrice;
        c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;
      } else {
        s = 0;
        c = 0;
      }

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateProfit", { t: "US", profit });
      return profit;
    },
    upbitSubOpenProfit() {
      var ex = this.$store.state.exchange.upbitSub;
      var s;
      var c;
      if (ex.pair == "KRW") {
        s = ex.startPrice ? ex.startPrice : 0;
        c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;
      } else if (ex.pair == "BTC") {
        s = ex.startPrice ? ex.startPrice : 0;
        c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;
      } else {
        s = 0;
        c = 0;
      }

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateOpenProfit", { t: "US", profit });
      return profit;
    },
    gateioProfit() {
      var ex = this.$store.state.exchange.gateio;
      var s = ex.startPrice ? ex.startPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateProfit", { t: "G", profit });
      return profit;
    },
    binanceBalance() {
      return this.$store.state.exchange.binance.balance.total;
    },
    binanceProfit() {
      var ex = this.$store.state.exchange.binance;
      var s = ex.balance.avgPrice ? ex.balance.avgPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateProfit", { t: "B", profit });
      return profit;
    },
    binanceOpenProfit() {
      var ex = this.$store.state.exchange.binance;
      var s = ex.startPrice ? ex.startPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateOpenProfit", { t: "B", profit });
      return profit;
    },
    binanceSubBalance() {
      return this.$store.state.exchange.binanceSub.balance.total;
    },
    binanceSubProfit() {
      var ex = this.$store.state.exchange.binanceSub;
      var s = ex.balance.avgPrice ? ex.balance.avgPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateProfit", { t: "BS", profit });
      return profit;
    },
    binanceSubOpenProfit() {
      var ex = this.$store.state.exchange.binanceSub;
      var s = ex.startPrice ? ex.startPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateOpenProfit", { t: "BS", profit });
      return profit;
    },
    binanceFuturesBalance() {
      return this.$store.state.exchange.binanceFutures.balance.total;
    },
    binanceFuturesProfit() {
      var ex = this.$store.state.exchange.binanceFutures;

      var s = ex.balance.avgPrice ? ex.balance.avgPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateProfit", { t: "BF", profit });
      return profit;
    },
    binanceFuturesOpenProfit() {
      var ex = this.$store.state.exchange.binanceFutures;

      var s = ex.startPrice ? ex.startPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateOpenProfit", { t: "BF", profit });
      return profit;
    },
    binanceFuturesSubBalance() {
      return this.$store.state.exchange.binanceFuturesSub.balance.total;
    },
    binanceFuturesSubProfit() {
      var ex = this.$store.state.exchange.binanceFuturesSub;

      var s = ex.balance.avgPrice ? ex.balance.avgPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateProfit", { t: "BFS", profit });
      return profit;
    },
    binanceFuturesSubOpenProfit() {
      var ex = this.$store.state.exchange.binanceFuturesSub;

      var s = ex.startPrice ? ex.startPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateOpenProfit", { t: "BFS", profit });
      return profit;
    },
    bithumbProfit() {
      var ex = this.$store.state.exchange.bithumb;
      var s = ex.balance.avgPrice ? ex.balance.avgPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }

      this.$store.commit("updateProfit", { t: "BT", profit });
      return profit;
    },
    bithumbBalance() {
      return this.$store.state.exchange.bithumb.balance.total;
    },
    bithumbOpenProfit() {
      var ex = this.$store.state.exchange.bithumb;
      var s = ex.startPrice ? ex.startPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }

      this.$store.commit("updateOpenProfit", { t: "BT", profit });
      return profit;
    },
    ftxProfit() {
      var ex = this.$store.state.exchange.ftx;

      var s = ex.balance.avgPrice ? ex.balance.avgPrice : 0;
      var c = ex.balance.currentPrice ? ex.balance.currentPrice : 0;

      var profit;
      if (s == 0 || c == 0) {
        profit = 0;
      } else {
        profit = (c / s) * 100 - 100;
      }
      this.$store.commit("updateProfit", { t: "F", profit });
      return profit;
    },
  },
  methods: {
    connectWebsocket() {
      if (!this.wsConn) {
        this.$store.dispatch("connectWebsockt");
      }
    },
    setActiveExchange() {
      if (this.exUpbit) {
        this.id = "U";
      } else if (this.exUpbitSub) {
        this.id = "US";
      } else if (this.exBinance) {
        this.id = "B";
      } else if (this.exBinanceSub) {
        this.id = "BS";
      } else if (this.exBinanceFutures) {
        this.id = "BF";
      } else if (this.exBinanceFuturesSub) {
        this.id = "BFS";
      } else if (this.exFtx) {
        this.id = "F";
      } else if (this.exGateio) {
        this.id = "G";
      } else if (this.exBithumb) {
        this.id = "BT";
      } else {
        this.id = null;
      }
    },
  },
  watch: {
    id: function (val) {
      this.$store.commit("selectExchange", { t: val });
    },
    select: function (val) {
      this.id = val;
    },
    wsConn: function (val) {
      if (!val) {
        this.wsTimer = setInterval(this.connectWebsocket, 400);
      } else {
        if (this.wsTimer) {
          clearInterval(this.wsTimer);
          this.wsTimer = null;
        }
      }
    },
    // 업비트 활성화 프로세서
    exUpbit: async function (val) {
      if (val) {
        this.id = "U";
      } else {
        this.setActiveExchange();
      }

      try {
        await this.$store.dispatch("getState", { exchange: "U" });
        await this.$store.dispatch("getBalance", { exchange: "U" });
      } catch {
        console.log("update upbit state");
      }

      if (val) {
        this.$store.dispatch("updateBtcPrice");
        // 루프 잔액조회
        const a = this;
        this.upbitTimer = setInterval(function () {
          t.$store.commit("updateCurrentPrice", { t: "U", data: a.upbitPrice });
          if (a.upbitBool) {
            a.upbitBool = false;
            a.$store.dispatch("getBalance", { exchange: "U" });
          } else {
            a.upbitBool = true;
          }
        }, 500);
        // websocket
        if (this.upbitWebsocket) {
          this.upbitWebsocket.close();
          this.upbitWebsocket = null;
        }
        const t = this;
        const sc = service.wwebsocketConnect(
          "wss://api.upbit.com/websocket/v1"
        );
        sc.binaryType = "arraybuffer";
        sc.onopen = () => {
          t.upbitWebsocket = sc;
          var obj = [
            {
              ticket:
                "asjdasjd aksd werksd sdkksd kk" +
                Math.floor(new Date().getTime() / 1000),
            },
            { type: "ticker", codes: [t.$store.state.exchange.upbit.market] },
            { format: "SIMPLE" },
          ];
          var message = JSON.stringify(obj);
          sc.send(message);
        };
        sc.onclose = () => {
          t.upbitWebsocket = null;
          t.upbitPrice = 0;
          t.$store.commit("updateCurrentPrice", { t: "U", data: 0 });
        };
        sc.onmessage = (evt) => {
          new Response(evt.data).arrayBuffer().then((buffer) => {
            var decoded = String.fromCharCode(...new Uint8Array(buffer));
            var obj = JSON.parse(decoded);
            a.upbitPrice = obj.tp;
          });
        };
      } else {
        // 타이머해제
        if (this.upbitTimer) {
          clearInterval(this.upbitTimer);
          this.upbitTimer = null;
        }

        // 웹소켓 해제
        if (this.upbitWebsocket) {
          this.upbitWebsocket.close();
          this.upbitWebsocket = null;
        }
      }
    },
    // 업비트 Sub 활성화 프로세서
    exUpbitSub: async function (val) {
      if (val) {
        if (this.id != "U") {
          this.id = "US";
        }
      } else {
        this.setActiveExchange();
      }

      try {
        await this.$store.dispatch("getState", { exchange: "US" });
        await this.$store.dispatch("getBalance", { exchange: "US" });
      } catch {
        console.log("update upbit sub state");
      }

      if (val) {
        this.$store.dispatch("updateBtcPrice");
        // 루프 잔액조회
        const a = this;
        this.upbitSubTimer = setInterval(function () {
          t.$store.commit("updateCurrentPrice", {
            t: "US",
            data: a.upbitSubPrice,
          });
          if (a.upbitSubBool) {
            a.upbitSubBool = false;
            a.$store.dispatch("getBalance", { exchange: "US" });
          } else {
            a.upbitSubBool = true;
          }
        }, 500);
        // websocket
        if (this.upbitSubWebsocket) {
          this.upbitSubWebsocket.close();
          this.upbitSubWebsocket = null;
        }
        const t = this;
        const sc = service.wwebsocketConnect(
          "wss://api.upbit.com/websocket/v1"
        );
        sc.binaryType = "arraybuffer";
        sc.onopen = () => {
          t.upbitSubWebsocket = sc;
          var obj = [
            {
              ticket:
                "asjdasjd aksd werksd sdkksd kka" +
                Math.floor(new Date().getTime() / 1000),
            },
            {
              type: "ticker",
              codes: [t.$store.state.exchange.upbitSub.market],
            },
            { format: "SIMPLE" },
          ];
          var message = JSON.stringify(obj);
          sc.send(message);
        };
        sc.onclose = () => {
          t.upbitSubWebsocket = null;
          t.upbitSubPrice = 0;
          t.$store.commit("updateCurrentPrice", { t: "US", data: 0 });
        };
        sc.onmessage = (evt) => {
          new Response(evt.data).arrayBuffer().then((buffer) => {
            var decoded = String.fromCharCode(...new Uint8Array(buffer));
            var obj = JSON.parse(decoded);
            a.upbitSubPrice = obj.tp;
          });
        };
      } else {
        // 타이머해제
        if (this.upbitSubTimer) {
          clearInterval(this.upbitSubTimer);
          this.upbitSubTimer = null;
        }

        // 웹소켓 해제
        if (this.upbitSubWebsocket) {
          this.upbitSubWebsocket.close();
          this.upbitSubWebsocket = null;
        }
      }
    },
    // 바이낸스 활성화 프로세서
    exBinance: async function (val) {
      if (val) {
        if (this.id != "U") {
          this.id = "B";
        }
      } else {
        this.setActiveExchange();
      }

      try {
        await this.$store.dispatch("getState", { exchange: "B" });
        await this.$store.dispatch("getBalance", { exchange: "B" });
      } catch {
        console.log("update binance state");
      }

      if (val) {
        // 루프 잔액조회
        const a = this;
        this.binanceTimer = setInterval(function () {
          t.$store.commit("updateCurrentPrice", {
            t: "B",
            data: a.binancePrice,
          });
          if (a.binanceBool) {
            a.binanceBool = false;
            a.$store.dispatch("getBalance", { exchange: "B" });
          } else {
            a.binanceBool = true;
          }
        }, 500);
        // websocket
        if (this.binanceWebsocket) {
          this.binanceWebsocket.close();
          this.binanceWebsocket = null;
        }
        // 거래소 웹소켓 시세 연결
        const t = this;
        const sc = service.wwebsocketConnect("wss://stream.binance.com/stream");
        sc.binaryType = "arraybuffer";
        sc.onopen = () => {
          t.binanceWebsocket = sc;
          var obj = {
            method: "SUBSCRIBE",
            params: [
              t.$store.state.exchange.binance.market.toLowerCase() + "@trade",
            ],
            id: 2,
          };
          var message = JSON.stringify(obj);
          sc.send(message);
        };
        sc.onclose = () => {
          t.binanceWebsocket = null;
          t.binancePrice = 0;
          t.$store.commit("updateCurrentPrice", { t: "B", data: 0 });
        };
        sc.onmessage = (evt) => {
          new Response(evt.data).arrayBuffer().then((buffer) => {
            var decoded = String.fromCharCode(...new Uint8Array(buffer));
            var obj = JSON.parse(decoded);
            if (obj.data) {
              t.binancePrice = obj.data.p;
            }
          });
        };
      } else {
        // 타이머해제
        if (this.binanceTimer) {
          clearInterval(this.binanceTimer);
          this.binanceTimer = null;
        }

        // 웹소켓 해제
        if (this.binanceWebsocket) {
          this.binanceWebsocket.close();
          this.binanceWebsocket = null;
        }
      }
    },
    // 바이낸스 Sub 활성화 프로세서
    exBinanceSub: async function (val) {
      if (val) {
        if (this.id != "U") {
          this.id = "BS";
        }
      } else {
        this.setActiveExchange();
      }

      try {
        await this.$store.dispatch("getState", { exchange: "BS" });
        await this.$store.dispatch("getBalance", { exchange: "BS" });
      } catch {
        console.log("update binance sub state");
      }

      if (val) {
        // 루프 잔액조회
        const a = this;
        this.binanceSubTimer = setInterval(function () {
          t.$store.commit("updateCurrentPrice", {
            t: "BS",
            data: a.binanceSubPrice,
          });
          if (a.binanceSubBool) {
            a.binanceSubBool = false;
            a.$store.dispatch("getBalance", { exchange: "BS" });
          } else {
            a.binanceSubBool = true;
          }
        }, 500);
        // websocket
        if (this.binanceSubWebsocket) {
          this.binanceSubWebsocket.close();
          this.binanceSubWebsocket = null;
        }
        // 거래소 웹소켓 시세 연결
        const t = this;
        const sc = service.wwebsocketConnect("wss://stream.binance.com/stream");
        sc.binaryType = "arraybuffer";
        sc.onopen = () => {
          t.binanceSubWebsocket = sc;
          var obj = {
            method: "SUBSCRIBE",
            params: [
              t.$store.state.exchange.binanceSub.market.toLowerCase() +
                "@trade",
            ],
            id: 2,
          };
          var message = JSON.stringify(obj);
          sc.send(message);
        };
        sc.onclose = () => {
          t.binanceSubWebsocket = null;
          t.binanceSubPrice = 0;
          t.$store.commit("updateCurrentPrice", { t: "BS", data: 0 });
        };
        sc.onmessage = (evt) => {
          new Response(evt.data).arrayBuffer().then((buffer) => {
            var decoded = String.fromCharCode(...new Uint8Array(buffer));
            var obj = JSON.parse(decoded);
            if (obj.data) {
              t.binanceSubPrice = obj.data.p;
            }
          });
        };
      } else {
        // 타이머해제
        if (this.binanceSubTimer) {
          clearInterval(this.binanceSubTimer);
          this.binanceSubTimer = null;
        }

        // 웹소켓 해제
        if (this.binanceSubWebsocket) {
          this.binanceSubWebsocket.close();
          this.binanceSubWebsocket = null;
        }
      }
    },
    // 바이낸스 선물 활성화 프로세서
    exBinanceFutures: async function (val) {
      if (val) {
        if (this.id != "U") {
          this.id = "BF";
        }
      } else {
        this.setActiveExchange();
      }

      try {
        await this.$store.dispatch("getState", { exchange: "BF" });
        await this.$store.dispatch("getBalance", { exchange: "BF" });
      } catch {
        console.log("update binance futures state");
      }

      if (val) {
        // 루프 잔액조회
        const a = this;
        this.binanceFuturesTimer = setInterval(function () {
          t.$store.commit("updateCurrentPrice", {
            t: "BF",
            data: a.binanceFuturesPrice,
          });
          if (a.binanceFuturesBool) {
            a.binanceFuturesBool = false;
            a.$store.dispatch("getBalance", { exchange: "BF" });
          } else {
            a.binanceFuturesBool = true;
          }
        }, 500);
        // websocket
        if (this.binanceFuturesWebsocket) {
          this.binanceFuturesWebsocket.close();
          this.binanceFuturesWebsocket = null;
        }
        // 거래소 웹소켓 시세 연결
        const t = this;
        const sc = service.wwebsocketConnect(
          "wss://fstream.binance.com/stream"
        );
        sc.binaryType = "arraybuffer";
        sc.onopen = () => {
          t.binanceFuturesWebsocket = sc;
          var obj = {
            method: "SUBSCRIBE",
            params: [
              t.$store.state.exchange.binanceFutures.market.toLowerCase() +
                "@aggTrade",
            ],
            id: 2,
          };
          var message = JSON.stringify(obj);
          sc.send(message);
        };
        sc.onclose = () => {
          t.binanceFuturesWebsocket = null;
          t.binanceFuturesPrice = 0;
          t.$store.commit("updateCurrentPrice", { t: "BF", data: 0 });
        };
        sc.onmessage = (evt) => {
          new Response(evt.data).arrayBuffer().then((buffer) => {
            var decoded = String.fromCharCode(...new Uint8Array(buffer));
            var obj = JSON.parse(decoded);
            if (obj.data) {
              t.binanceFuturesPrice = obj.data.p;
            }
          });
        };
      } else {
        // 타이머해제
        if (this.binanceFuturesTimer) {
          clearInterval(this.binanceFuturesTimer);
          this.binanceFuturesTimer = null;
        }

        // 웹소켓 해제
        if (this.binanceFuturesWebsocket) {
          this.binanceFuturesWebsocket.close();
          this.binanceFuturesWebsocket = null;
        }
      }
    },
    // 바이낸스 선물 Sub 활성화 프로세서
    exBinanceFuturesSub: async function (val) {
      if (val) {
        if (this.id != "U") {
          this.id = "BFS";
        }
      } else {
        this.setActiveExchange();
      }

      try {
        await this.$store.dispatch("getState", { exchange: "BFS" });
        await this.$store.dispatch("getBalance", { exchange: "BFS" });
      } catch {
        console.log("update binance futures sub state");
      }

      if (val) {
        // 루프 잔액조회
        const a = this;
        this.binanceFuturesSubTimer = setInterval(function () {
          t.$store.commit("updateCurrentPrice", {
            t: "BFS",
            data: a.binanceFuturesSubPrice,
          });
          if (a.binanceFuturesSubBool) {
            a.binanceFuturesSubBool = false;
            a.$store.dispatch("getBalance", { exchange: "BFS" });
          } else {
            a.binanceFuturesSubBool = true;
          }
        }, 500);
        // websocket
        if (this.binanceFuturesSubWebsocket) {
          this.binanceFuturesSubWebsocket.close();
          this.binanceFuturesSubWebsocket = null;
        }
        // 거래소 웹소켓 시세 연결
        const t = this;
        const sc = service.wwebsocketConnect(
          "wss://fstream.binance.com/stream"
        );
        sc.binaryType = "arraybuffer";
        sc.onopen = () => {
          t.binanceFuturesSubWebsocket = sc;
          var obj = {
            method: "SUBSCRIBE",
            params: [
              t.$store.state.exchange.binanceFuturesSub.market.toLowerCase() +
                "@aggTrade",
            ],
            id: 2,
          };
          var message = JSON.stringify(obj);
          sc.send(message);
        };
        sc.onclose = () => {
          t.binanceFuturesSubWebsocket = null;
          t.binanceFuturesSubPrice = 0;
          t.$store.commit("updateCurrentPrice", { t: "BFS", data: 0 });
        };
        sc.onmessage = (evt) => {
          new Response(evt.data).arrayBuffer().then((buffer) => {
            var decoded = String.fromCharCode(...new Uint8Array(buffer));
            var obj = JSON.parse(decoded);
            if (obj.data) {
              t.binanceFuturesSubPrice = obj.data.p;
            }
          });
        };
      } else {
        // 타이머해제
        if (this.binanceFuturesSubTimer) {
          clearInterval(this.binanceFuturesSubTimer);
          this.binanceFuturesSubTimer = null;
        }

        // 웹소켓 해제
        if (this.binanceFuturesSubWebsocket) {
          this.binanceFuturesSubWebsocket.close();
          this.binanceFuturesSubWebsocket = null;
        }
      }
    },
    // 게이트아이오 활성화 프로세서
    exGateio: async function (val) {
      if (val) {
        if (this.id != "U") {
          this.id = "G";
        }
      } else {
        this.setActiveExchange();
      }

      try {
        await this.$store.dispatch("getState", { exchange: "G" });
        await this.$store.dispatch("getBalance", { exchange: "G" });
      } catch {
        console.log("update gateio state");
      }

      if (val) {
        // 루프 잔액조회
        const a = this;
        this.gateioTimer = setInterval(function () {
          t.$store.commit("updateCurrentPrice", {
            t: "G",
            data: a.gateioPrice,
          });
          if (a.gateioBool) {
            a.gateioBool = false;
            a.$store.dispatch("getBalance", { exchange: "G" });
          } else {
            a.gateioBool = true;
          }
        }, 500);
        // websocket
        if (this.gateioWebsocket) {
          this.gateioWebsocket.close();
          this.gateioWebsocket = null;
        }
        // 거래소 웹소켓 시세 연결
        const t = this;
        const sc = service.wwebsocketConnect("wss://ws.gate.io/v3/");
        sc.binaryType = "arraybuffer";
        sc.onopen = () => {
          t.gateioWebsocket = sc;
          var obj = {
            method: "trades.subscribe",
            params: [t.$store.state.exchange.gateio.market],
            id: 6721975,
          };

          var message = JSON.stringify(obj);
          sc.send(message);
        };
        sc.onclose = () => {
          t.gateioWebsocket = null;
          t.gateioPrice = 0;
          t.$store.commit("updateCurrentPrice", { t: "G", data: 0 });
        };
        sc.onmessage = (evt) => {
          new Response(evt.data).arrayBuffer().then((buffer) => {
            var decoded = String.fromCharCode(...new Uint8Array(buffer));
            var obj = JSON.parse(decoded);
            if (obj.method == "trades.update") {
              t.gateioPrice = obj.params[1][0].price;
            }
          });
        };
      } else {
        // 타이머해제
        if (this.gateioTimer) {
          clearInterval(this.gateioTimer);
          this.gateioTimer = null;
        }

        // 웹소켓 해제
        if (this.gateioWebsocket) {
          this.gateioWebsocket.close();
          this.gateioWebsocket = null;
        }
      }
    },
    // FTX 활성화 프로세서
    exFtx: async function (val) {
      if (val) {
        if (this.id != "U") {
          this.id = "F";
        }
      } else {
        this.setActiveExchange();
      }

      try {
        await this.$store.dispatch("getState", { exchange: "F" });
        await this.$store.dispatch("getBalance", { exchange: "F" });
      } catch {
        console.log("update ftx state");
      }

      if (val) {
        // 루프 잔액조회
        const a = this;
        this.ftxTimer = setInterval(function () {
          t.$store.commit("updateCurrentPrice", { t: "F", data: a.ftxPrice });
          if (a.ftxBool) {
            a.ftxBool = false;
            a.$store.dispatch("getBalance", { exchange: "F" });
          } else {
            a.ftxBool = true;
          }
        }, 500);
        // websocket
        if (this.ftxWebsocket) {
          this.ftxWebsocket.close();
          this.ftxWebsocket = null;
        }
        // 거래소 웹소켓 시세 연결
        const t = this;
        const sc = service.wwebsocketConnect("wss://ftx.com/ws/");
        sc.binaryType = "arraybuffer";
        sc.onopen = () => {
          t.ftxWebsocket = sc;
          var obj = {
            op: "subscribe",
            channel: "trades",
            market: t.$store.state.exchange.ftx.market,
          };

          var message = JSON.stringify(obj);
          sc.send(message);
        };
        sc.onclose = () => {
          t.ftxWebsocket = null;
          t.ftxPrice = 0;
          t.$store.commit("updateCurrentPrice", { t: "F", data: 0 });
        };
        sc.onmessage = (evt) => {
          new Response(evt.data).arrayBuffer().then((buffer) => {
            var decoded = String.fromCharCode(...new Uint8Array(buffer));
            var obj = JSON.parse(decoded);
            if (obj.type == "update") {
              t.ftxPrice = obj.data[0].price;
            }
          });
        };
      } else {
        // 타이머해제
        if (this.ftxTimer) {
          clearInterval(this.ftxTimer);
          this.ftxTimer = null;
        }

        // 웹소켓 해제
        if (this.ftxWebsocket) {
          this.ftxWebsocket.close();
          this.ftxWebsocket = null;
        }
      }
    },
    // 빗썸 활성화 프로세서
    exBithumb: async function (val) {
      if (val) {
        if (this.id != "U") {
          this.id = "BT";
        }
      } else {
        this.setActiveExchange();
      }

      try {
        await this.$store.dispatch("getState", { exchange: "BT" });
        await this.$store.dispatch("getBalance", { exchange: "BT" });
      } catch {
        console.log("update bithumb state");
      }

      if (val) {
        // 루프 잔액조회
        const a = this;
        this.bithumbTimer = setInterval(function () {
          t.$store.commit("updateCurrentPrice", {
            t: "BT",
            data: a.bithumbPrice,
          });
          if (a.bithumbBool) {
            a.bithumbBool = false;
            a.$store.dispatch("getBalance", { exchange: "BT" });
          } else {
            a.bithumbBool = true;
          }
        }, 500);
        // websocket
        if (this.bithumbWebsocket) {
          this.bithumbWebsocket.close();
          this.bithumbWebsocket = null;
        }
        // 거래소 웹소켓 시세 연결
        const t = this;
        const sc = service.wwebsocketConnect("wss://pubwss.bithumb.com/pub/ws");
        sc.binaryType = "arraybuffer";
        sc.onopen = () => {
          t.bithumbWebsocket = sc;
          var obj = {
            type: "transaction",
            symbols: [t.$store.state.exchange.bithumb.market],
          };

          var message = JSON.stringify(obj);
          sc.send(message);
        };
        sc.onclose = () => {
          t.bithumbWebsocket = null;
          t.bithumbPrice = 0;
          t.$store.commit("updateCurrentPrice", { t: "BT", data: 0 });
        };
        sc.onmessage = (evt) => {
          new Response(evt.data).arrayBuffer().then((buffer) => {
            var decoded = String.fromCharCode(...new Uint8Array(buffer));
            var obj = JSON.parse(decoded);
            if (obj.type && obj.type == "transaction") {
              t.bithumbPrice = obj.content.list[0].contPrice;
            }
          });
        };
      } else {
        // 타이머해제
        if (this.bithumbTimer) {
          clearInterval(this.bithumbTimer);
          this.bithumbTimer = null;
        }

        // 웹소켓 해제
        if (this.bithumbWebsocket) {
          this.bithumbWebsocket.close();
          this.bithumbWebsocket = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.event-icon {
  color: yellow !important;
}
.non-chip {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
</style>