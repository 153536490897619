var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container--fluid"},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"12","sm":"8","md":"4","lg":"4"}},[(this.$store.state.status.upbit)?_c('v-badge',{attrs:{"color":"green--text","content":(_vm.upbitProfit > 10
            ? '(' + _vm.upbitOpenProfit.toFixed(0) + '%)'
            : '(' + _vm.upbitOpenProfit.toFixed(1)) + '%)',"overlap":"","top":"","left":"","offset-x":"85"}},[_c('v-badge',{attrs:{"color":_vm.upbitProfit > 0
              ? 'transparent red--text'
              : 'transparent blue--text',"content":_vm.upbitBalance
              ? (_vm.upbitProfit > 10
                  ? _vm.upbitProfit.toFixed(0)
                  : _vm.upbitProfit.toFixed(1)) + '%'
              : '-%',"overlap":"","left":"","offset-x":"40"}},[_c('v-btn',{staticClass:"black font-weight-bold pa-0 ml-1",class:[_vm.id == 'U' ? 'teal darken-3' : ''],on:{"click":function($event){_vm.id = 'U'}}},[_c('v-chip',{staticClass:"non-chip font-weight-bold black--text",attrs:{"x-small":"","label":"","color":"orange darken-2"}},[_vm._v(" "+_vm._s(this.$store.state.exchange.upbit.symbol)+" "),_c('label',{staticClass:"white--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.$store.state.exchange.upbit.rank)+" ")])]),_c('span',{staticClass:"blue--text text--darken-3 caption font-weight-bold",staticStyle:{"background-color":"white"}},[_vm._v("Upbit")])],1)],1)],1):_vm._e(),(this.$store.state.status.upbitSub)?_c('v-badge',{attrs:{"color":"green--text","content":(_vm.upbitProfit > 10
            ? '(' + _vm.upbitSubOpenProfit.toFixed(0) + '%)'
            : '(' + _vm.upbitSubOpenProfit.toFixed(1)) + '%)',"overlap":"","top":"","right":"","offset-x":"60"}},[_c('v-badge',{attrs:{"color":_vm.upbitSubProfit > 0
              ? 'transparent red--text'
              : 'transparent blue--text',"content":_vm.upbitSubBalance
              ? (_vm.upbitSubProfit > 10
                  ? _vm.upbitSubProfit.toFixed(0)
                  : _vm.upbitSubProfit.toFixed(1)) + '%'
              : '-%',"overlap":"","left":"","offset-x":"40"}},[_c('v-btn',{staticClass:"black font-weight-bold pa-0 ml-1",class:[_vm.id == 'US' ? 'teal darken-3' : ''],on:{"click":function($event){_vm.id = 'US'}}},[_c('v-chip',{staticClass:"non-chip font-weight-bold black--text",attrs:{"x-small":"","label":"","color":"orange darken-2"}},[_vm._v(" "+_vm._s(this.$store.state.exchange.upbitSub.symbol)+" "),_c('label',{staticClass:"white--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.$store.state.exchange.upbitSub.rank)+" ")])]),_c('span',{staticClass:"blue--text text--darken-3 caption font-weight-bold",staticStyle:{"background-color":"white"}},[_vm._v("Upbit2")])],1)],1)],1):_vm._e(),(this.$store.state.status.binance)?_c('v-badge',{attrs:{"color":"green--text","content":(_vm.binanceProfit > 10
            ? '(' + _vm.binanceOpenProfit.toFixed(0) + '%)'
            : '(' + _vm.binanceOpenProfit.toFixed(1)) + '%)',"overlap":"","top":"","left":"","offset-x":"85"}},[_c('v-badge',{attrs:{"color":_vm.binanceProfit > 0
              ? 'transparent red--text'
              : 'transparent blue--text',"content":_vm.binanceBalance
              ? (_vm.binanceProfit > 10
                  ? _vm.binanceProfit.toFixed(0)
                  : _vm.binanceProfit.toFixed(1)) + '%'
              : '-%',"overlap":"","left":"","offset-x":"40"}},[_c('v-btn',{staticClass:"black font-weight-bold pa-0 ml-1",class:[_vm.id == 'B' ? 'teal darken-3' : ''],on:{"click":function($event){_vm.id = 'B'}}},[_c('v-chip',{staticClass:"non-chip font-weight-bold black--text",attrs:{"x-small":"","label":"","color":"orange darken-2"}},[_vm._v(" "+_vm._s(this.$store.state.exchange.binance.symbol)+" "),_c('label',{staticClass:"white--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.$store.state.exchange.binance.rank)+" ")])]),_c('span',{staticClass:"yellow--text text--darken-1 caption font-weight-bold"},[_vm._v("Binance")])],1)],1)],1):_vm._e(),(this.$store.state.status.binanceSub)?_c('v-badge',{attrs:{"color":"green--text","content":(_vm.binanceSubOpenProfit > 10
            ? '(' + _vm.binanceSubOpenProfit.toFixed(0) + '%)'
            : '(' + _vm.binanceSubOpenProfit.toFixed(1)) + '%)',"overlap":"","top":"","left":"","offset-x":"85"}},[(this.$store.state.status.binanceSub)?_c('v-badge',{attrs:{"color":_vm.binanceSubProfit > 0
              ? 'transparent red--text'
              : 'transparent blue--text',"content":_vm.binanceSubBalance
              ? (_vm.binanceSubProfit > 10
                  ? _vm.binanceSubProfit.toFixed(0)
                  : _vm.binanceSubProfit.toFixed(1)) + '%'
              : '-%',"overlap":"","left":"","offset-x":"40"}},[_c('v-btn',{staticClass:"black font-weight-bold pa-0 ml-1",class:[_vm.id == 'BS' ? 'teal darken-3' : ''],on:{"click":function($event){_vm.id = 'BS'}}},[_c('v-chip',{staticClass:"non-chip font-weight-bold black--text",attrs:{"x-small":"","label":"","color":"orange darken-2"}},[_vm._v(" "+_vm._s(this.$store.state.exchange.binanceSub.symbol)+" "),_c('label',{staticClass:"white--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.$store.state.exchange.binanceSub.rank)+" ")])]),_c('span',{staticClass:"yellow--text text--darken-1 caption font-weight-bold"},[_vm._v("Binance2")])],1)],1):_vm._e()],1):_vm._e(),(this.$store.state.status.binanceFutures)?_c('v-badge',{attrs:{"color":"green--text","content":(_vm.binanceFuturesOpenProfit > 10
            ? '(' + _vm.binanceFuturesOpenProfit.toFixed(0) + '%)'
            : '(' + _vm.binanceFuturesOpenProfit.toFixed(1)) + '%)',"overlap":"","top":"","left":"","offset-x":"85"}},[_c('v-badge',{attrs:{"color":_vm.binanceFuturesProfit > 0
              ? 'transparent red--text'
              : 'transparent blue--text',"content":_vm.binanceFuturesBalance
              ? (_vm.binanceFuturesProfit > 10
                  ? _vm.binanceFuturesProfit.toFixed(0)
                  : _vm.binanceFuturesProfit.toFixed(1)) + '%'
              : '-%',"overlap":"","left":"","offset-x":"40"}},[_c('v-btn',{staticClass:"black font-weight-bold pa-0 ml-1",class:[_vm.id == 'BF' ? 'teal darken-3' : ''],on:{"click":function($event){_vm.id = 'BF'}}},[_c('v-chip',{staticClass:"non-chip font-weight-bold black--text",attrs:{"x-small":"","label":"","color":"orange darken-2"}},[_vm._v(" "+_vm._s(this.$store.state.exchange.binanceFutures.symbol)+" "),_c('label',{staticClass:"white--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.$store.state.exchange.binanceFutures.rank)+" ")])]),_c('span',{staticClass:"yellow--text text--darken-1 caption font-weight-bold"},[_vm._v("Binance"),_c('label',{staticClass:"red--text"},[_vm._v("M")])])],1)],1)],1):_vm._e(),(this.$store.state.status.binanceFuturesSub)?_c('v-badge',{attrs:{"color":"green--text","content":(_vm.binanceFuturesSubOpenProfit > 10
            ? '(' + _vm.binanceFuturesSubOpenProfit.toFixed(0) + '%)'
            : '(' + _vm.binanceFuturesSubOpenProfit.toFixed(1)) + '%)',"overlap":"","top":"","left":"","offset-x":"85"}},[_c('v-badge',{attrs:{"color":_vm.binanceFuturesSubProfit > 0
              ? 'transparent red--text'
              : 'transparent blue--text',"content":_vm.binanceFuturesSubBalance
              ? (_vm.binanceFuturesSubProfit > 10
                  ? _vm.binanceFuturesSubProfit.toFixed(0)
                  : _vm.binanceFuturesSubProfit.toFixed(1)) + '%'
              : '-%',"overlap":"","left":"","offset-x":"40"}},[_c('v-btn',{staticClass:"black font-weight-bold pa-0 ml-1",class:[_vm.id == 'BFS' ? 'teal darken-3' : ''],on:{"click":function($event){_vm.id = 'BFS'}}},[_c('v-chip',{staticClass:"non-chip font-weight-bold black--text",attrs:{"x-small":"","label":"","color":"orange darken-2"}},[_vm._v(" "+_vm._s(this.$store.state.exchange.binanceFuturesSub.symbol)+" "),_c('label',{staticClass:"white--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.$store.state.exchange.binanceFuturesSub.rank)+" ")])]),_c('span',{staticClass:"yellow--text text--darken-1 caption font-weight-bold"},[_vm._v("Binance"),_c('label',{staticClass:"red--text"},[_vm._v("M")]),_vm._v("2")])],1)],1)],1):_vm._e(),(this.$store.state.status.bithumb)?_c('v-badge',{attrs:{"color":"green--text","content":(_vm.upbitProfit > 10
            ? '(' + _vm.bithumbOpenProfit.toFixed(0) + '%)'
            : '(' + _vm.bithumbOpenProfit.toFixed(1)) + '%)',"overlap":"","top":"","left":"","offset-x":"85"}},[_c('v-badge',{attrs:{"color":_vm.bithumbProfit > 0
              ? 'transparent red--text'
              : 'transparent blue--text',"content":_vm.bithumbBalance
              ? (_vm.bithumbProfit > 10
                  ? _vm.bithumbProfit.toFixed(0)
                  : _vm.bithumbProfit.toFixed(1)) + '%'
              : '-%',"overlap":"","left":"","offset-x":"40"}},[_c('v-btn',{staticClass:"black font-weight-bold pa-0 ml-1",class:[_vm.id == 'BT' ? 'teal darken-3' : ''],on:{"click":function($event){_vm.id = 'BT'}}},[_c('v-chip',{staticClass:"non-chip font-weight-bold black--text",attrs:{"x-small":"","label":"","color":"orange darken-2"}},[_vm._v(" "+_vm._s(this.$store.state.exchange.bithumb.symbol)+" "),_c('label',{staticClass:"white--text text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.$store.state.exchange.bithumb.rank)+" ")])]),_c('span',{staticClass:"orange--text text--darken-2 caption font-weight-bold",staticStyle:{"background-color":"white"}},[_vm._v("Bithumb")])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }