import Vue from 'vue'
import Vuex from 'vuex'
import service from '../common/service'
import sound from '../common/sound'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /* default server */
    ips: [],
    subIps: [],
    auth: false,
    isLogin: false,
    /* webscoket */
    websocket: {
      conn: null,
    },
    iphone: false,
    /* exchange status */
    status: {
      upbit: false,
      upbitSub: false,
      binance: false,
      binanceSub: false,
      binanceFutures: false,
      binanceFuturesSub: false,
      gateio: false,
      ftx: false,
      bithumb: false,
      huobi: false
    },
    exchange: {
      upbit: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
      upbitSub: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
      binance: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
      binanceSub: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
      binanceFutures: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
      binanceFuturesSub: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
      gateio: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
      ftx: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
      bithumb: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
      huobi: { 
        active: "",
        title: "",
        notice: "",
        symbol: "",
        rank: 0,
        pair: "",
        market: "",
        startPrice: "",
        timestamp: "",
        profit: 0,
        openProfit: 0,
        balance: {
          symbol: "",
          total: 0,
          balance: 0,
          locked: 0,
          avgPrice: 0,
          currentPrice: 0,
        },
      },
    },
    /* etc variable */
    btcPrice: 0,
    beep: false,
    voice: false,
    vibrate: false,
    audio: false,
    runOnce: true,
    select: null,
    audioQueue: [],
    duplicateAudio: false,
  },
  mutations: {
    setIphone(state) {
      state.iphone = true;
    },
    updateRunOnce(state) {
      state.runOnce = false;
    },
    updateServer(state, payload) {
      state.ips = payload.ips;
      state.subIps = payload.subIps;
      state.auth = payload.auth;

    },
    updateWebsocket(state, conn) {
      state.websocket.conn = conn;
    },
    updateStatus(state, param) {
      state.status = param;
    },
    addAudioQueue(state, param) {
      state.audioQueue.push(param);
    },
    removeAudioQueue(state) {
      state.audioQueue.shift();
    },
    updateExchange(state, { t, data }) {
      let ex;
      switch (t) {
        case 'U':
          ex = state.exchange.upbit;
          break;
        case 'US':
          ex = state.exchange.upbitSub;
          break;
        case 'B':
          ex = state.exchange.binance;
          break;
        case 'BS':
          ex = state.exchange.binanceSub;
          break;
        case 'BF':
          ex = state.exchange.binanceFutures;
          break;
        case 'BFS':
          ex = state.exchange.binanceFuturesSub;
          break;
        case 'G':
          ex = state.exchange.gateio;
          break;
        case 'F':
          ex = state.exchange.ftx;
          break;
        case 'BT':
          ex = state.exchange.bithumb;
          break;
        case 'H':
          ex = state.exchange.huobi;
          break;
        default:
          return;
      }
      ex.active = data.active;
      ex.title = `[${data.symbol}][${data.rank}] `+data.title;
      ex.notice = data.notice;
      ex.market = data.market;
      ex.pair = data.pair;
      ex.rank = data.rank;
      ex.startPrice = data.startPrice;
      ex.symbol = data.symbol;
      ex.timestamp = data.timestamp;
    },
    updateBalance(state, { t, data }) {
      let ex;
      switch (t) {
        case 'U':
          ex = state.exchange.upbit;
          break;
        case 'US':
          ex = state.exchange.upbitSub;
          break;
        case 'B':
          ex = state.exchange.binance;
          break;
        case 'BS':
          ex = state.exchange.binanceSub;
          break;
        case 'BF':
          ex = state.exchange.binanceFutures;
          break;
        case 'BFS':
          ex = state.exchange.binanceFuturesSub;
          break;
        case 'G':
          ex = state.exchange.gateio;
          break;
        case 'F':
          ex = state.exchange.ftx;
          break;
        case 'BT':
          ex =   state.exchange.bithumb;
          break;
        case 'H':
          ex =   state.exchange.huobi;
          break;
        default:
          return;
      }

      ex.balance.symbol = data.symbol;
      ex.balance.total = parseFloat(data.total ? data.total : 0);
      ex.balance.balance = parseFloat(data.balance ? data.balance : 0);
      ex.balance.locked = parseFloat(data.locked ? data.locked : 0);
      ex.balance.avgPrice = parseFloat(data.avgPrice ? data.avgPrice : 0);
    },
    updateCurrentPrice(state, { t, data }) {
      let ex;
      switch (t) {
        case 'U':
          ex = state.exchange.upbit;
          break;
        case 'US':
          ex = state.exchange.upbitSub;
          break;
        case 'B':
          ex = state.exchange.binance;
          break;
        case 'BS':
          ex = state.exchange.binanceSub;
          break;
        case 'BF':
          ex = state.exchange.binanceFutures;
          break;
        case 'BFS':
          ex = state.exchange.binanceFuturesSub;
          break;
        case 'G':
          ex = state.exchange.gateio;
          break;
        case 'F':
          ex = state.exchange.ftx;
          break;
        case 'BT':
          ex = state.exchange.bithumb;
          break;
        case 'H':
          ex = state.exchange.huobi;
          break;
        default:
          return;
      }
      ex.balance.currentPrice = parseFloat(data);
    },
    updateProfit(state, { t, profit }) {
      let ex;
      switch (t) {
        case 'U':
          ex = state.exchange.upbit;
          break;
        case 'US':
          ex = state.exchange.upbitSub;
          break;
        case 'B':
          ex = state.exchange.binance;
          break;
        case 'BS':
          ex = state.exchange.binanceSub;
          break;
        case 'BF':
          ex = state.exchange.binanceFutures;
          break;
        case 'BFS':
          ex = state.exchange.binanceFuturesSub;
          break;
        case 'G':
          ex = state.exchange.gateio;
          break;
        case 'F':
          ex = state.exchange.ftx;
          break;
        case 'BT':
          ex = state.exchange.bithumb;
          break;
        case 'H':
          ex = state.exchange.huobi;
          break;
        default:
          return;
      }
      ex.profit = parseFloat(profit);
    },
    updateOpenProfit(state, { t, profit }) {
      let ex;
      switch (t) {
        case 'U':
          ex = state.exchange.upbit;
          break;
        case 'US':
          ex = state.exchange.upbitSub;
          break;
        case 'B':
          ex = state.exchange.binance;
          break;
        case 'BS':
          ex = state.exchange.binanceSub;
          break;
        case 'BF':
          ex = state.exchange.binanceFutures;
          break;
        case 'BFS':
          ex = state.exchange.binanceFuturesSub;
          break;
        case 'G':
          ex = state.exchange.gateio;
          break;
        case 'F':
          ex = state.exchange.ftx;
          break;
        case 'BT':
          ex = state.exchange.bithumb;
          break;
        case 'H':
          ex = state.exchange.huobi;
          break;
        default:
          return;
      }
      ex.openProfit = parseFloat(profit);
    },
    updateBtcPrice(state, { price }) {
      state.btcPrice = price;
    },
    updateBeep(state, { enabled }) {
      state.beep = enabled;
    },
    updateVibrate(state, { enabled }) {
      state.vibrate = enabled;
    },
    updateVoice(state, { enabled }) {
      state.voice = enabled;
    },
    updateAudio(state, { audio }) {
      state.audio = audio;
    },
    selectExchange(state, { t }) {
      state.select = t;
    },
    changeLogin(state, { enabled }) {
      state.isLogin = enabled;
    }
  },
  actions: {
    init({ state, commit, dispatch }) {
      if (state.runOnce) {
        commit('updateRunOnce');
        const server = localStorage.getItem('server') ? localStorage.getItem('server') : '';
        const subServer = localStorage.getItem('subServer') ? localStorage.getItem('subServer') : '';
        const secret = localStorage.getItem('secret') ? localStorage.getItem('secret') : '';
        var ips = server ? server.split(',') : [];
        var subIps = subServer ? subServer.split(',') : [];
        return dispatch('login', { ips: ips, subIps: subIps, pw: secret });
      }
    },
    async login({ commit }, {ips, subIps, pw}) {
      commit('changeLogin', { enabled: true });
      if (ips.length == 0) {
          commit('updateServer', {ips: [], auth: false});
          commit('changeLogin', { enabled: false });
          return Promise.reject();
      }

      service.setAuth(pw);
      for (var ip of ips) {
        try {
          await service.ping(ip)
        } catch {
          commit('updateServer', {ips: [], auth: false});
          commit('changeLogin', { enabled: false });
          return Promise.reject();
        }
      }
      
      commit('changeLogin', { enabled: false });
      commit('updateServer', {ips, subIps, auth: true});
      return  Promise.resolve();
    },
    connectWebsockt( { state, commit }) {
      const sc = service.websocketConnect(state.ips[0]);
      commit('updateWebsocket', sc);
      sc.binaryType = 'arraybuffer';
      // sc.onopen = () => {
      //   commit('updateWebsocket', sc);
      // };
      sc.onmessage = (stream) => {
        commit('updateStatus', JSON.parse(stream.data));
      };
      sc.onclose = () => {
        if (state.websocket.conn) {
          commit('updateWebsocket', null);
        }
      };
      sc.onerror = () => {
        if (state.websocket.conn) {
          state.websocket.conn.close();
          commit('updateWebsocket', null);
        }
      };
    },
    disconnectWebsockt( { state, commit }) {
      const sc = state.websocket.conn;
      if (sc) {
        commit('updateWebsocket', null);
        sc.close();
      }
    },
    async getState( { state, commit }, param) {
      var res1;
      try {
        // 상태 업데이트
        res1 = await service.getStatus(state.ips[0], param);
        commit('updateExchange', { t: param.exchange, data: res1.data });
      } catch (err) {
        console.log(err);
        return
      }

      // 거래소 진행중일 경우 오디오/진동 알림
      if (!res1.data.active) {
        return;
      }

      if (state.duplicateAudio) {
        return;
      }
      state.duplicateAudio = true;
      setTimeout(function() {
        state.duplicateAudio = false;
    }, 10000);

      // 진동
      if( state.vibrate ) {
        navigator.vibrate([1000, 300, 1000, 300, 1000, 300, 1000, 300, 1000, 300, 1000, 300, 1000, 300, 1000, 300, 1000, 300, 1000, 300]);
      }

      var best = false;
      if (res1.data.title.includes('상장')) {
        best = true;
      }
      /* 오디오 테스트코드 */
      // add audio queue
      var preSnd;
      if (!state.audio) {
        // 음성 재생
        if (state.voice || state.beep) {
          preSnd = best ? "data:audio/mp3;base64," + sound.sharara : "data:audio/mp3;base64," + sound.ringdong;
          commit('addAudioQueue', preSnd);
          if (state.beep) {
            commit('addAudioQueue', preSnd);
          }
          if (state.voice) {
            try {
              const res2 = await service.getAudio(state.ips[0], { message: res1.data.title });
              commit('addAudioQueue', "data:audio/wav;base64," + res2.data);
            } catch {
              commit('addAudioQueue', "data:audio/wav;base64," + sound.voice);
            }
          }
        }
      }


      /* 오디오 테스트코드 종료 */

      /* 오디오 원래코드*/
      // //오디오
      // var audio;
      // var preSnd;
      // if (!state.audio) {
      //   if (state.voice) {
      //     // 음성 재생
      //     commit('updateAudio', { audio: true })
      //     preSnd = best ? new Audio("data:audio/mp3;base64," + sound.sharara) : new Audio("data:audio/mp3;base64," + sound.ringdong);
      //     preSnd.play();
      //     try {
      //       const res2 = await service.getAudio(state.ips[0], { message: res1.data.title });
      //       audio = new Audio("data:audio/wav;base64," + res2.data);
      //     } catch {
      //       audio = new Audio(require('@/assets/voice.wav'));
      //     }
      //     audio.addEventListener('ended', () => {
      //       commit('updateAudio', { audio: false })
      //     });
      //     audio.play();
      //   } else if (state.beep) {
      //     // 비프음 재생
      //     commit('updateAudio', { audio: true })
      //     preSnd = best ?  new Audio("data:audio/mp3;base64," + sound.sharara) : new Audio("data:audio/mp3;base64," + sound.ringdong);
      //     preSnd.addEventListener('ended', () => {
      //       commit('updateAudio', { audio: false })
      //     })
      //     preSnd.play();
      //   }
      // }
    },
    getBalance( { state, commit }, param) {
      service.getBalance(state.ips[0], param).then((res) => {
        commit('updateBalance', { t: param.exchange, data: res.data })
      });
    },
    updateBtcPrice( { state, commit } ) {
      service.getTick(state.ips[0], { exchange: 'U', symbol: 'BTC' }).then((res) => {
        commit('updateBtcPrice', { price: res.data })
      });
    },
    async tradeSell( { state }, param) {
      var ok = false;
      if (param.exchange == 'BF' && param.loop)  {
        // loop..
        for (var ip of state.ips) {
          try {
            await  service.tradeSell(ip, param);
            ok = true;
          } catch {
            console.log('tradeSell catch event.');
          } 
        }

        if (ok) {
          return  Promise.resolve();
        } else {
          return  Promise.reject();
        }
      } else {
        return service.tradeSell(state.ips[0], param);
      }
    },
    tradeCancel( { state }, param) {
      return service.tradeCancel(state.ips[0], param);
    },
    reset( { state, commit }, param) {
      commit('updateAudio', { audio: false })
      for (var ip of state.ips) {
        service.reset(ip, param);
      }
      for (var subIp of state.subIps) {
        service.reset(subIp, param);
      }
    },
  },
  modules: {
  }
})
