<template>
  <v-main>
    <upbit-view v-if="select == 'U'"></upbit-view>
    <upbit-sub-view v-if="select == 'US'"></upbit-sub-view>
    <binance-view v-if="select == 'B'"></binance-view>
    <binance-sub-view v-if="select == 'BS'"></binance-sub-view>
    <binance-futures-view v-if="select == 'BF'"></binance-futures-view>
    <binance-futures-sub-view v-if="select == 'BFS'"></binance-futures-sub-view>
    <gateio-view v-if="select == 'G'"></gateio-view>
    <ftx-view v-if="select == 'F'"></ftx-view>
    <bithumb-view v-if="select == 'BT'"></bithumb-view>
    <huobi-view v-if="select == 'H'"></huobi-view>
    <wait-view v-if="!select"></wait-view>
  </v-main>
</template>

<script>
import UpbitView from '../../views/UpbitView.vue'
import UpbitSubView from '../../views/UpbitSubView.vue'
import BinanceView from '../../views/BinanceView.vue'
import BinanceSubView from '../../views/BinanceSubView.vue'
import BinanceFuturesView from '../../views/BinanceFuturesView.vue'
import BinanceFuturesSubView from '../../views/BinanceFuturesSubView.vue'
import GateioView from '../../views/GateioView.vue'
import FtxView from '../../views/FtxView.vue'
import BithumbView from '../../views/BithumbView.vue'
import HuobiView from '../../views/HuobiView.vue'
import WaitView from '../../views/WaitView.vue';
export default {
  name: 'CustomMain',
  components: {
    UpbitView,
    UpbitSubView,
    BinanceView,
    BinanceSubView,
    BinanceFuturesView,
    BinanceFuturesSubView,
    GateioView,
    FtxView,
    BithumbView,
    HuobiView,
    WaitView,
  },
  data: () => ({
  }), 
  computed: {
    select() {
      return this.$store.state.select;
    },
  },
  watch: {
    select(newVal) {
      if (newVal) {
        document.exitFullscreen(); 
      }
    },
  },
  created() {
    // if (localStorage.getItem('vibrate')) {
    //   this.$store.commit('updateVibrate', { enabled: true });
    // }
    // if (localStorage.getItem('voice')) {
    //   this.$store.commit('updateVoice', { enabled: true });
    // } else if (localStorage.getItem('beep')) {
    //   this.$store.commit('updateBeep', { enabled: true });
    // }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
     handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        // 앱이 전경으로 복귀했을 때 수행할 작업
        if (this.$store.state.iphone)
          this.$store.dispatch('disconnectWebsockt');
      }
      //  else if (document.visibilityState === 'hidden') {
      //   // 앱이 배경으로 갔을 때 수행할 작업
      // }
    }
  },
};
</script>
