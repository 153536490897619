<template>
  <div @click="test">
    <v-alert
      v-if="!connection"
      border="top"
      type="error"
      outlined
      class="white"
    >
      server disconnected.
    </v-alert>
    <v-alert
      v-if="!audioPlaying"
      border="top"
      type="error"
      outlined
      class="white"
    >
      audio not available.
    </v-alert>
    <custom-navigation />
    <custom-main />
  </div>
</template>

<script lang="ts">
import CustomMain from "./components/CustomMain.vue";
import CustomNavigation from "./components/CustomNavigation.vue";
import sound from "../common/sound";

export default {
  name: "Layout",
  components: {
    CustomMain,
    CustomNavigation,
  },
  data() {
    return {
      audio: null,
    };
  },
  computed: {
    connection() {
      return this.$store.state.websocket.conn ? true : false;
    },
    audioPlaying() {
      return this.audio ? true : false;
    },
  },
  methods: {
    async test() {
      if (this.audio) {
        return;
      }

      this.audio = new Audio("data:audio/mp3;base64," + sound.short);
      this.audio.muted = true;
      this.audio.play();

      // clear old audio queue
      if (this.$store.state.audioQueue.length > 0) {
        this.$store.state.audioQueue = [];
      }
      setInterval(async () => {
        if (this.$store.state.audioQueue.length > 0) {
          if (!this.audio.muted) {
            return;
          }

          try {
            let src = this.$store.state.audioQueue.shift();
            this.audio.muted = false;
            this.audio.src = src;
            await this.audio.play();
            this.audio.onended = () => {
              this.audio.muted = true;
            };
          } catch (e) {
            console.log(e);
            this.audio.muted = true;
          } 
        }
      }, 300); // Consume an item every 0.5 seconds
    },
  },
};
</script>
