import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import Layout from '../layouts/index.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/',
    component: Layout,
    meta: {
      requiredServer: true,
    },
  },
  

  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
  const requiredServer = to.matched.some(record => record.meta.requiredServer)
  // 최초 로그인시 세션체크
  try {
    await store.dispatch('init');
  } catch {
    console.log('server not connected')
  }
  

  // 권한이 필요한 페이지 라우트
  if (requiredServer && !store.state.auth) {
    next({
      path: '/login',
    })
    return;
  }
  next();
})


export default router
